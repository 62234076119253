import React, { Component } from 'react'
import { AutoSizer, List, WindowScroller, CellMeasurer, CellMeasurerCache, InfiniteLoader } from 'react-virtualized'
import { connect } from 'react-redux'
import { updateTrackData, updateSingleTrackData, PREVIOUS_WITH_PLAYER, NEXT_WITH_PLAYER, playWithButton, destroySession } from '../actions'
import { injectIntl } from "gatsby-plugin-intl"
import { LazyLoadImage } from './LazyLoadImage'
import 'react-lazy-load-image-component/src/effects/opacity.css'
import PlayStateType from '../classes/PlayStateType'
import Moment from 'react-moment'
import 'moment-timezone'

import Spinner from './Spinner'
import SOTDData from '../classes/SOTDData'

import Footer from './Footer'
import Wave from './Wave'
import PlayButton from './PlayButton'

import { componentToRgb, rgbToHex } from '../classes/Color'
import Tweener from '../classes/Tweener'

import '../css/tracks.scss'
import mlogo from '../img/mlogo@2x.png'
import itunes from '../img/itunes.svg'
import { isBrowser, isSafari } from 'react-device-detect'

Moment.globalTimezone = 'Asia/Tokyo'
Moment.globalFormat = 'YYYY.M.D'

class Tracks extends Component {
  constructor(props, context) {
    super(props, context)
    this.cache = new CellMeasurerCache({
      fixedWidth: true,
      defaultHeight: 480,
      minHeight: 72,
    })
    this.state = {
      listHeight: 800,
      overscanRowCount: 1,
      scrollToIndex: undefined,
      useDynamicRowHeight: true,
      animatedScrollTop: -1, 
      bgAlpha: 0.1, 
      titleShow: true, 
      height: 0, 
      width: 0
    }
    this.isNowLoading = false
    this.rowRenderer = this.rowRenderer.bind(this)
    this.isRowLoaded = this.isRowLoaded.bind(this)
    this.loadMoreRows = this.loadMoreRows.bind(this)
    this.footer = null
    this.needUpdateScrollTop = -1

    this.lang = this.props.intl.locale
    this.isJP = this.props.intl.locale === 'ja'
    this.tweener = new Tweener()

    this.sotd = null
  }
  
  componentDidMount() {
 
    console.log("componentDidMount", this.props)
    //console.log(this.props.location.action)
    //console.log(this.props.history)

    //this.sotd = new SOTDData(this.lang)

    //console.log("scrollTop",scrollTop)
    //console.log("bbb", this.props.location.state?.key ?? "-")
    let isRestored = false

    if (!this.sotd) {
      this.sotd = new SOTDData(this.lang)
      this.sotd.restore({
        tracks: this.props.tracks, 
        latestDate: this.props.latestDate, 
        tillDate: this.props.tillDate, 
        loadedPageIndex: this.props.loadedPageIndex, 
        isFullLoaded: this.props.isFullLoaded
      })
    }

    let scrollTop = null
    let data = null

    if (!this.initialized) {

      const locationKey = window.sessionStorage.getItem("locationKey")

      console.info(locationKey, this.props.location.state?.key);

      if (locationKey) {
        if (locationKey === this.props.location.state?.key) {
          console.info( "This page is back");
          scrollTop = parseInt(window.sessionStorage.getItem("scrollTop"))
          const jsonString = window.sessionStorage.getItem("data")
          data = JSON.parse(jsonString)
        }
        else {
          console.info( "This page is not back");
        }
      }
      window.sessionStorage.removeItem("locationKey")
      window.sessionStorage.removeItem("scrollTop")
      window.sessionStorage.removeItem("data")

      if (scrollTop != null && data != null) {
        console.log("restore", data.cache)
        isRestored = true
        this.sotd.restore(data)
        this.props.updateTrackData(data)
        this.cache._cellHeightCache = { ...this.cache._cellHeightCache, ...data.cache }
        this.cache._rowHeightCache = { ...this.cache._rowHeightCache, ...data.cache }
        //console.log("restore2")
        this.needUpdateScrollTop = scrollTop
      }
      window.addEventListener('beforeunload', this.beforeunload.bind(this))
      window.addEventListener("resize", this.updateDimensions)

      window.addEventListener("mousewheel", this.handleWheelEvent)

      this.initialized = true
    }

    if (!isRestored && this.sotd.latestDate === "0") {
      this.loadRows().then(tracks => {
        if (tracks) {
          this.props.updateTrackData({
            tracks, 
            ...this.sotd.getLoadingState()
          })
        }
      }).catch(err => {
        console.log(err)
      })
    }

    if (this.state.width === 0) {
      this.setState({ width: window.innerWidth, height: window.innerHeight })
    }
  }
  updateDimensions = () => {
    this.setState({
      height: window.innerHeight, 
      width: window.innerWidth,
    })
    //console.log("updateDimensions")
  }

  async loadRows() {
    try {
      const tracks = await this.sotd.loadMoreRows(this.props.tracks)
      return tracks
    }
    catch (err) {
      throw err
    }
  }

  loadMoreRows() {
    //console.log("loadMoreRows start")
    if (!this.sotd) return;
    this.loadRows().then(tracks => {
      if (tracks && tracks.length !== 0) {
        //console.log("loadMoreRows finish", tracks)
        //console.log(tracks, tracks.length, this.props.tracks.length)
        //loadingのセルと、表示できなかったダミーセルの両方をクリアする必要がある
        //この場合、SOTDDataの中で前のtrakcs（参照したままっぽい）がpopされているので
        //length + 1と lengthのインデックスが対象になる
        this.cache.clear(this.props.tracks.length, 0)
        this.cache.clear(this.props.tracks.length + 1, 0)
        this.props.updateTrackData({
          tracks, 
          ...this.sotd.getLoadingState()
        })
      }
    }).catch(err => {
      console.log(err)
    })
  }

  componentDidUpdate() {
    //console.log("componentDidUpdate")
    if (this.needUpdateScrollTop !== -1) {
      //console.log("componentDidUpdate2", this.needUpdateScrollTop)
      this.refs.recomputeRowHeights()
      this.refs.measureAllRows()
      const targetY = this.needUpdateScrollTop 
      this.needUpdateScrollTop = -1
      this.positionTo(targetY) //ここで直接setStateでanimatedScrollYを実行しても別ページに行って戻ってきたときうまくいかないがTweenerを使ってrenderが実行されるようにするとよいっぽい
    }
    else if (this.state.animatedScrollTop === -1) {
      if (this.props.actionType === NEXT_WITH_PLAYER || this.props.actionType === PREVIOUS_WITH_PLAYER) {
        this.scrollTo(this.props.currentPlayingTrackIndex)
      }
    }
  }

  componentWillUnmount() {
    this.tweener.cancel()
    //console.log("componentWillUnmount tracks")
    this.beforeunload(null)
    window.removeEventListener('beforeunload', this.beforeunload.bind(this))
    window.removeEventListener("resize", this.updateDimensions)
    window.removeEventListener("mousewheel", this.handleWheelEvent)
    this.props.destroySession()
  }

  handleWheelEvent = event => {
    event.preventDefault()
    window.scrollTo(0, window.pageYOffset - event.wheelDelta * 0.3)
  }

  isRowLoaded({ index }) {
    //console.log("isRowLoaded", index, !!this.props.tracks[index])
    return !!this.props.tracks[index]
  }

  beforeunload(e) {
    if (!this.refs || !window) return;
    //console.log(this.refs.props.scrollTop)
    window.sessionStorage.setItem('scrollTop', String(this.refs.props.scrollTop))
    window.sessionStorage.setItem('locationKey', this.props.location.state?.key ?? "-")

    const json = {
      "latestDate": this.sotd.latestDate, 
      "tillDate": this.sotd.tillDate,
      "loadedPageIndex": this.sotd.loadedPageIndex,
      "isFullLoaded": this.sotd.isFullLoaded,
      "tracks": this.props.tracks,
      "cache": this.cache._cellHeightCache, 
    }
    const jsonString = JSON.stringify(json)
    window.sessionStorage.setItem('data', jsonString)
  }

  scrollTo = index => {
    let targetY = -84
    for (let i = 0; i < index; i++) {
      const key = `${i}-0`
      const height = this.cache._cellHeightCache[key]
      if (!isNaN(height)) targetY += height
    }
    if (targetY < 0) targetY = 0
    //console.log(this.refs.props.scrollTop, targetY)
    this.tweener.animate(
      this.refs.props.scrollTop,
      targetY,
      (scrollTop, callback) => this.setState({animatedScrollTop: scrollTop}, callback),
      () => this.finishScroll()
    )
  }

  positionTo = targetY => {
    this.tweener.animate(
      this.refs.props.scrollTop,
      targetY,
      (scrollTop, callback) => this.setState({animatedScrollTop: scrollTop}, callback),
      () => this.finishScroll(), 
      1
    )
  }

  updateScroll = (scrollTop) => {
    this.refs.scrollToPosition(scrollTop)
  }

  finishScroll = () => {
    //console.log("finishScroll")
    this.setState({ animatedScrollTop: -1 })
  }

  rowCount = () => {
    if (this.props.trackCount === 0) return 0;
    return !this.sotd.isFullLoaded ? this.props.tracks.length + 1 : this.props.tracks.length
  }

  estimatedListHeight = () => {
    if (!this.sotd) return 0;
    let h = 0
    for (let key of Object.keys(this.cache._cellHeightCache)) {
      const height = this.cache._cellHeightCache[key]
      if (!isNaN(height)) h += height
    }
    if (!this.sotd.isFullLoaded) h += this.cache._minHeight
    return h
  }

  render() {
    //console.log("render tracks")
    if (this.state.width === 0) {
      return (<></>)
    }

    const listStyle = (this.state.bgAlpha === 0) ? { backgroundColor: "transparent" } : { backgroundColor: `rgba(0, 0, 0, ${this.state.bgAlpha})` }

    const { overscanRowCount } = this.state
    const rowCount = this.rowCount()
    const titleClassNames = this.state.titleShow ? "sotdSectionHeader show" : "sotdSectionHeader hide"
   
    return (<>
      <div className={titleClassNames}>
        <h2 className="sotdTitle"><img src={mlogo} alt="NEW MONAURAL" width="44" height="44" />SONG OF THE DAY</h2>
        <p className="sotdCopy">{this.props.intl.formatMessage({ id: 'sotd.subTitle' })}</p>
      </div>
  
      <InfiniteLoader
        isRowLoaded={this.isRowLoaded}
        loadMoreRows={this.loadMoreRows}
        rowCount={rowCount}
        minimumBatchSize={1}
        threshold={1}
      >
      {({ onRowsRendered }) => (
        <WindowScroller>
          {({ height, isScrolling, onChildScroll, scrollTop }) => (
            <AutoSizer disableHeight>
              {({width}) => (
                <div className="sotdList" style={{ height: this.estimatedListHeight(), paddingTop: 108 }}>
                  <List 
                    style={listStyle}
                    className="sotdListList"
                    deferredMeasurementCache = {this.cache} 
                    autoHeight
                    ref={ref => this.refs = ref}
                    height={height}
                    overscanRowCount={overscanRowCount}
                    noRowsRenderer={this.noRowRenderer}
                    rowCount={rowCount}
                    rowHeight={this.cache.rowHeight}
                    rowRenderer={this.rowRenderer}
                    scrollTop={this.state.animatedScrollTop === -1 ? scrollTop : this.state.animatedScrollTop}
                    isScrolling={isScrolling}
                    onScroll={e => { this.onScroll(e.scrollTop); onChildScroll(e)}}
                    width={width}
                    onRowsRendered={onRowsRendered}
                  />
                </div>
              )}
            </AutoSizer>
          )}
        </WindowScroller>
      )}
      </InfiniteLoader>
 
      <Footer scrollTo={this.scrollTo} childRef={ref => (this.footer = ref)} />
    </>)
  }

  onJacketClick = index => {
    //console.log(index, this.props.tracks[index].showLR)

    this.props.updateSingleTrackData(index, !this.props.tracks[index].showLR)
  }

  onScroll = scrollTop => {

    if (this.state.width === 0)return;

    let titleShow, bgAlpha

    if (this.state.width > 414) {
      if (scrollTop > 800) {
        bgAlpha = 0.8
      }
      else if (scrollTop <= 100) {
        bgAlpha = 0.1
      }
      else {
        bgAlpha = 0.8 * scrollTop / 800
      }
    }
    else {
      bgAlpha = 0
    }

    if (this.state.titleShow) {
      titleShow = scrollTop < 80
    }
    else {
      titleShow = scrollTop < 70
    }

    if (this.state.bgAlpha !== bgAlpha || this.state.titleShow !== titleShow) {
      this.setState({ bgAlpha, titleShow })
    }
  }

  timeIntervalToDateJSX = (value, index) => {
    const months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"]
    const d = new Date(parseInt(value) * 1000)
    const paddingTop = (index != 0) ? 0 : (this.state.width > 414) ? 200 : 100
    return <div className="sotdDate" style={{ paddingTop }}><span>{d.getDate()}</span> {months[d.getMonth()]} {d.getFullYear()}</div>
  }

  onClickPlayButton = (index) => {
    console.log(this.props.currentPlayingTrackIndex, index, this.props.playState)
    if (this.props.currentPlayingTrackIndex === index) {
      if (this.props.playState === PlayStateType.PLAY) {
        this.footer.audio.current.pause()
      }
      else if (this.props.playState === PlayStateType.PAUSE) {
        this.footer.audio.current.play()
      }
      else {
        this.props.playWithButton(index, this.props.tracks[index])
      }
    }
    else {
      this.props.playWithButton(index, this.props.tracks[index])
    }
  }

  rowRenderer({index, isScrolling, key, parent, style}) {
    if (this.state.width === 0) return null;
    const track = this.props.tracks[index]
    if (track === undefined) {
      return (
        <CellMeasurer key={key} cache={this.cache} parent={parent} columnIndex={0} rowIndex={index}>
          <div style={style}></div>
        </CellMeasurer>
      )
    }

    let waveScale = 1
    let waveWidth = 480
    if (this.state.width < 480) {
      waveScale = this.state.width / 480
      waveWidth = this.state.width
    }
    const waveHeight = Math.floor(waveWidth * 0.2875)
    const waveProps = {
      width: waveWidth, 
      height: waveHeight,
      sideMargin: 12 * waveScale, 
      topMargin: 32 * waveScale, 
      bottomMargin: 4 * waveScale, 
      track: track,
    }

    let imageProps = {
      width: waveWidth, 
      height: waveHeight,
      className: "jacketImage",
      src: track.jacket, 
      alt: track.trackName
    }
    if (isBrowser && !isSafari) {
      imageProps.effect = "opacity";
    }

    //console.log("row index", index)

    const playButtonProps = {
      index: index, 
      targetTrack: track, 
      onClick: this.onClickPlayButton
    }

    let wp = <div></div>
    if (track.wpt) {
      if (track.wpu.length < 10) {
        wp = <div className="wp">{track.wpt}{track.wpq}</div>
      }
      else {
        wp = <div className="wp">{track.wpt}<p><a href={track.wpu}>{track.wpq}</a></p></div>
      }
    }
 
    const waveClassNames = track.showLR ? "wave show" : "wave hide"

    return (
      <CellMeasurer key={key} cache={this.cache} parent={parent} columnIndex={0} rowIndex={index}>
        <div style={style}>
          {
            track.loading === true ? 
            (
              <div className="spinner">
                <Spinner radius={24} color={"#adadad"} stroke={3} visible={true} />
              </div>
            ) :  
            (
              <article>
                {this.timeIntervalToDateJSX(track.date, index)}
                <div className="jacketContainer" style={{ backgroundColor: rgbToHex(componentToRgb(track.c2)) }}>
                <Wave {...waveProps} className={waveClassNames} />
              
                <LazyLoadImage 
                  {...imageProps}
                  onClick ={() => this.onJacketClick(index)}
                />
         
                </div>
                <h3>{track.trackName}</h3>
                <h4>{track.albumAndArtist}</h4>
                <PlayButton {...playButtonProps} />
                <div className="attributes">
              
                <LazyLoadImage 
                  visibleByDefault={true}
                  wdth={28}
                  height={28}
                  effect="opacity"
                  src={track.athumb} 
                  alt={track.name}
                  className="athumb" 
                  />
           
                  <span className="dateAndGenre"><Moment format="YYYY">{track.rdate}</Moment>, {track.genre}</span>
                  <a href={track.url} className="price"><img src={itunes} alt="Get it on iTunes Store" /></a>
                </div>
                {wp}
              </article>
            )
          }
        </div>
      </CellMeasurer>
    )
  }

  noRowRenderer() {
    return (
      <div className="spinnerZero">
        <Spinner radius={48} color={"#adadad"} stroke={3} visible={true} />
      </div>
    )
  }
}

const mapStateToProps = state => {
  return { playState: state.player.playState, 
          currentPlayingTrackIndex: state.player.currentPlayingTrackIndex,
          tracks: state.player.tracks, 
          trackCount: state.player.trackCount, 
          latestDate: state.player.latestDate,  
          tillDate: state.player.tillDate,  
          loadedPageIndex: state.player.loadedPageIndex, 
          isFullLoaded: state.player.isFullLoaded, 
          actionType: state.player.actionType
        }
}
const mapDispatchToProps = ({ playWithButton, updateTrackData, updateSingleTrackData, destroySession })

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Tracks))
