import React, { Component } from 'react'
import  { connect } from 'react-redux'
import { playWithButton, pauseWithButton } from '../actions'
import PlayStateType from '../classes/PlayStateType'
import '../css/tracks.scss'

import btPlayerPlay from '../img/btPlayerPlay@2x.png'
import btPlayerPause from '../img/btPlayerPause@2x.png'


class PlayButton extends Component {
  render() {
    let src = btPlayerPlay
    if (this.props.currentPlayingTrackIndex === this.props.index) {
      if (this.props.playState === PlayStateType.PLAY) {
        src = btPlayerPause
      }
    }
    return (
      <img src={src} className="playButton" alt={"Play"} onClick={() => this.props.onClick(this.props.index)} />
    )
  }
}

const mapStateToProps = state => ({ playState: state.player.playState, 
                                    currentPlayingTrackIndex: state.player.currentPlayingTrackIndex, 
                                    track: state.player.track })
const mapDispatchToProps = ({ playWithButton, pauseWithButton })

export default connect(mapStateToProps, mapDispatchToProps)(PlayButton)

