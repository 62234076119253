class Tweener {
    constructor() {
        this.requestID = -1
    }
    animate = (fromValue, toValue, onUpdate, onComplete, duration = 600) => {
        this.cancel()
        const startTime = performance.now()
        const tick = () => {
            const elapsed = performance.now() - startTime
            this.requestID = window.requestAnimationFrame(() => onUpdate(
                this.getValue(fromValue, toValue, elapsed, duration),
                elapsed <= duration ? tick : onComplete
            ))
        }
        tick()
    }

    getValue = (start, end, elapsed, duration) => {
        if (elapsed > duration) return end;
        return start + (end - start) * this.easing(elapsed / duration)
    }

    easing = t => {
        return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1
    }

    cancel = () => {
        if (this.requestID !== -1) {
            window.cancelAnimationFrame(this.requestID)
        }
        this.requestID = -1
    }
}

export default Tweener
