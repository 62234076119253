import React, { Component } from 'react'
import '../css/Spinner.scss'

class Spinner extends Component {
  render() {
    return (
      <div className='spinnerGraphic' style={{
          borderRadius: this.props.radius / 2, 
          width: this.props.radius, 
          height: this.props.radius, 
          borderColor: this.props.color, 
          borderWidth: this.props.stroke, 
          display: this.props.visible ? 'block' : 'none'
      }}/>
    )
  }
}

export default Spinner
