
export const componentToRgb = rgb => {
    rgb = rgb > 0xffffff ? 0xffffff : rgb
    const r = rgb >> 16
    const g = (rgb >> 8) & 0x00ff
    const b = rgb & 0x0000ff
    return { r: r, g: g, b: b } 
}
export const rgbToHex = ({r, g, b}) => {
    return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
}
