import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { componentToRgb, rgbToHex } from '../classes/Color'

class Wave extends Component {

  constructor(props, context) {
    super(props, context)
    this.waveWidth = 1
    this.waveMargin = 4
    this.finishDraw = false
    this.data = null
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.finishDraw) {
      if (prevProps.width !== this.props.width) {
        this.finishDraw = false
      }
    }
    this.updateCanvas()
  }

  updateCanvas() {
    if (this.finishDraw) return;

    if (!this.data) {
      const waveString = this.props.track.wave
      if (waveString.length === 0) return;
        
      const arr = waveString.split(",")
      if (arr.count < 4) return;

      const waveCount = Number(arr[1])
      const compareCount = Number(arr[2])
      if (isNaN(waveCount) || isNaN(compareCount)) return;
    
      if (arr.count < 3 + waveCount + compareCount - 1) return;

      const arrW = []
      const arrC = []
      for (let i = 3; i < 3 + waveCount; i += 4) {
        const x = Number(arr[i])
        const y = Number(arr[i + 1])
        const width = Number(arr[i + 2])
        const height = Number(arr[i + 3])
        if (isNaN(x) || isNaN(y) || isNaN(width) || isNaN(height)) return;
        arrW.push({ x, y, width, height })
      }

      for (let i = 3 + waveCount; i < 3 + waveCount + compareCount; i++) {
        const n = Number(arr[i])
        if (isNaN(n)) return;
        arrC.push(n)
      }
      this.data = this.buildResult(compareCount, arrW, arrC)
    }
    this.draw()
    this.finishDraw = true
    return
  }

  buildResult = (compareCountForAllDuration, arrWave, arrCompareForWave) => {
    const compareLength = arrCompareForWave.length
    if (arrWave.length === 0 || compareLength === 0) return {};
    let total = 0
    let minPercentage = 100
    let maxPercentage = 0
    let minIndex = 0
    let maxIndex = 0
    for (let i = 0; i < compareLength; i++) {
      let value = Math.round(arrCompareForWave[i] * 100) / 100
      if (value < minPercentage) {
        minPercentage = value
        minIndex = i
      }
      if (value >= maxPercentage) {
        maxPercentage = value
          maxIndex = i
      }
      total += value
    }
    return {
              averagePercentage: total / 100 * compareLength, 
              compareResult: {
                                compareCount: compareLength,
                                minPercentage,
                                minIndex,
                                maxPercentage,
                                maxIndex
                              }, 
              compareCountForAllDuration, 
              arrWave, 
              arrCompareForWave
            }
  }

  calcurateWave1Width = (width, sideMargin, count) => count === 0 ? 0 : (width - sideMargin * 2) / count

  setupCanvas(canvas) {
    const dpr = window.devicePixelRatio || 1
    const rect = canvas.getBoundingClientRect()
    canvas.width = rect.width * dpr
    canvas.height = rect.height * dpr
    const ctx = canvas.getContext('2d')
    ctx.scale(dpr, dpr)
    return ctx
  }

  draw = () => {
    const { canvas } = this
    const context = this.setupCanvas(canvas)

    const { width, height, bottomMargin, topMargin, sideMargin } = this.props

    context.clearRect(0, 0, width, height)

    const waveLayoutScale = width / 375
    const waveHeight = (height - bottomMargin - topMargin) * 0.9

    // background
    const color = componentToRgb(this.props.track.c2)
    const gradient = context.createLinearGradient(0, topMargin, 0, height)
    gradient.addColorStop(0, `rgba(${color.r}, ${color.g}, ${color.b}, 0)`)
    gradient.addColorStop(1, `rgba(${color.r}, ${color.g}, ${color.b}, 1)`)

    context.fillStyle = gradient
    context.fillRect(0, 0, width, height)

    // wave
    const wh2 = waveHeight / 2
    const count = this.data.arrWave.length
    const compareCount = this.data.arrCompareForWave.length

    const color0 = componentToRgb(this.props.track.c0)
    const color1 = componentToRgb(this.props.track.c1)
    for (let i = 0; i < count; i++) {        
      let cx = sideMargin + (this.waveWidth + this.waveMargin) * waveLayoutScale * i
      
      let r = this.data.arrWave[i]

      //R
      context.fillStyle = `rgba(${color0.r}, ${color0.g}, ${color0.b}, 0.6)`
      const rh = wh2 * r.width
      context.fillRect(cx, height - rh - bottomMargin, 1, rh)

      //L
      context.fillStyle = `rgba(${color1.r}, ${color1.g}, ${color1.b}, 0.6)`
      const lh = wh2 * r.x
      context.fillRect(cx, height - rh - lh - bottomMargin, 1, lh)
    }
 
    // rate
    context.strokeStyle = `rgba(${color1.r}, ${color1.g}, ${color1.b}, 0.9)`
    context.lineWidth = 1

    const wave1width = this.calcurateWave1Width(width, sideMargin, this.data.compareCountForAllDuration)

    let arr = [];

    for (let i = 0; i < compareCount; i++) {
      const cx = sideMargin + wave1width * i
      const value = this.data.arrCompareForWave[i]
      arr.push({ x: cx, y: height - waveHeight + waveHeight * (100 - value) / 100 - bottomMargin })
    }
    arr = this.getInterpolatedPathByCatmullRom(arr)

    context.beginPath()  
    context.moveTo(arr[0].x, arr[0].y)
    for (let i = 1; i < arr.length; i++) {
      context.lineTo(arr[i].x, arr[i].y)
    }
    context.stroke()

    //text
    const cr = this.data.compareResult 
    const minValue = cr.minPercentage
    const maxValue = cr.maxPercentage
    const minIndex = cr.minIndex
    const maxIndex = cr.maxIndex
    const padding = 4
  
    context.font = '12px "HelveticaNeue", "Helvetica", sans-serif'
    context.textAlign = "start"
    context.textBaseLine = "middle"
    const tMax = this.getFormattedPercentValue(maxValue) + "%"
    const measureMax = context.measureText(tMax)
    let tMaxRect = new Rect(0, 0, measureMax.width, measureMax.actualBoundingBoxDescent + measureMax.actualBoundingBoxAscent)
 
    const tMin = this.getFormattedPercentValue(minValue) + "%"
    const measureMin = context.measureText(tMin)
    let tMinRect = new Rect(0, 0, measureMin.width, measureMin.actualBoundingBoxDescent + measureMin.actualBoundingBoxAscent)
    
    const textYOffset = 11
    let cx = this.props.sideMargin + wave1width * minIndex

    let pMin = new Point(cx, height - bottomMargin - waveHeight + waveHeight * (100 - minValue) / 100)
    tMinRect.center = new Point(pMin.x, pMin.y + textYOffset)

    cx = this.props.sideMargin + wave1width * maxIndex
    let pMax = new Point(cx, height - bottomMargin - waveHeight + waveHeight * (100 - maxValue) / 100)
    tMaxRect.center = new Point(pMax.x, pMax.y - textYOffset)

    let lbLRY = -10 + topMargin

    let lrRect = new Rect(sideMargin - 5, lbLRY, 37, 17)

    if (lrRect.intersects(tMinRect)) { //minがタイトルと重なった場合は
      tMinRect.y = lrRect.bottom + 2 //タイトルの少し下に移動
    }
    else if (tMinRect.bottom > height) { //minが下辺より下だった場合は
      tMinRect.center = new Point(pMin.x, pMin.y - textYOffset) //ラインの上側に配置する
    }
    if (tMinRect.left < sideMargin) {
      tMinRect.x = sideMargin
    }
    else if (tMinRect.right > width - sideMargin) {
      tMinRect.x = width - sideMargin - tMinRect.width
    }
    if (tMaxRect.left < sideMargin) {
      tMaxRect.x = sideMargin
    }
    else if (tMaxRect.right > width - sideMargin) {
      tMaxRect.x = width - sideMargin - tMaxRect.width
    }
    if (minIndex === maxIndex) { //ほとんどありえないが、minとmaxのインデックスが同じ
      tMaxRect = null //maxを表示しない
      pMax = null
    }
    else {
      if (lrRect.intersects(tMaxRect)) { //maxがタイトルと重なった場合
        lbLRY = -32 + topMargin
        lrRect.y = lbLRY 
      }
      if (tMaxRect.intersects(tMinRect)) { //maxがminと重なった場合は
        var f = tMaxRect.copy()
        if (maxIndex < minIndex) { //maxがminより左
          f.x = tMinRect.x - 5 - f.width //重ならないようにminの左へ移動
          if (f.x < sideMargin) {
            f.x = sideMargin
            tMinRect.x = sideMargin + f.width + 5
          }
        }
        else { //maxがminより右
          f.x = tMinRect.right + 5 //重ならないようにminの右へ移動
          if (f.x + f.size.width > width - sideMargin) {
            f.x = width - sideMargin - f.width
            tMinRect.x = f.x - 5 - tMinRect.width
          }
        }
        tMaxRect = f
      }
    }

    context.fillStyle = `rgba(${color.r}, ${color.g}, ${color.b}, 0.5)`
    this.fillRoundRect(context, tMinRect.getPaddedRect(padding), 3)
    if (tMaxRect) {
      this.fillRoundRect(context, tMaxRect.getPaddedRect(padding), 3)
    }
    context.fillStyle = `rgba(${color1.r}, ${color1.g}, ${color1.b}, 1)`
    context.save()
    context.transform(1, 0, 0, 1, tMinRect.x, tMinRect.y + tMinRect.height)
    context.fillText(tMin, 0, 0)
    context.restore()
    if (tMaxRect) {
      context.save()
      context.transform(1, 0, 0, 1, tMaxRect.x, tMaxRect.y + tMaxRect.height)
      context.fillText(tMax, 0, 0)
      context.restore()
    }

    //circle
    context.fillStyle = `rgba(${color.r}, ${color.g}, ${color.b}, 1)`
    context.strokeStyle = `rgba(${color1.r}, ${color1.g}, ${color1.b}, 1)`

    context.beginPath()
    context.arc(pMin.x, pMin.y, 2.5, 0, Math.PI * 2, true)
    context.fill()
    context.stroke()
    if (tMaxRect) {
      context.beginPath()
      context.arc(pMax.x, pMax.y, 2.5, 0, Math.PI * 2, true)
      context.fill()
      context.stroke()
    }

    // L=R
    const textColor1RGB = rgbToHex(color1)
    const bgColorRGB = rgbToHex(color)
    const xml = `<svg xmlns="http://www.w3.org/2000/svg" width="36px" height="17px" viewBox="0 0 36 17" version="1.1"><g id="Artboard" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><path d="M35.8568063,8.5 L31.1766592,16.875 L4.82334075,16.875 L0.143193692,8.5 L4.82334075,0.125 L31.1766592,0.125 L35.8568063,8.5 Z" id="Polygon" stroke="${textColor1RGB}" stroke-width="0.25" fill="${bgColorRGB}"></path><path d="M13.390625,12.7275391 L13.390625,11.4033203 L9.51171875,11.4033203 L9.51171875,4.27246094 L8,4.27246094 L8,12.7275391 L13.390625,12.7275391 Z M20.7714844,7.60253906 L20.7714844,6.34277344 L15,6.34277344 L15,7.60253906 L20.7714844,7.60253906 Z M20.7714844,10.3623047 L20.7714844,9.10253906 L15,9.10253906 L15,10.3623047 L20.7714844,10.3623047 Z M24.5117188,12.7275391 L24.5117188,9.55761719 L26.1875,9.55761719 L27.8457031,12.7275391 L29.5742188,12.7275391 L27.7285156,9.32324219 C28.7363281,8.95996094 29.3222656,8.02832031 29.3222656,6.91503906 C29.3222656,5.29199219 28.2089844,4.27246094 26.4335938,4.27246094 L23,4.27246094 L23,12.7275391 L24.5117188,12.7275391 Z M26.2560938,8.35644531 L24.51,8.35644531 L24.51,5.52636719 L26.2209375,5.52636719 C27.181875,5.52636719 27.7678125,6.06542969 27.7678125,6.94433594 C27.7678125,7.84082031 27.2170313,8.35644531 26.2560938,8.35644531 Z" id="L=R" fill="${textColor1RGB}" fill-rule="nonzero"></path></g></svg>`
    const svg = new Blob([xml], { type:"image/svg+xml" })
    const img = new Image()
    const domURL = URL
    const url = domURL.createObjectURL(svg)
    img.src = url
    img.onload = () => {
      context.drawImage(img, lrRect.x, lrRect.y)
      domURL.revokeObjectURL(url)
    }
  }

  fillRoundRect = (ctx, rect, radius) => {
    const { x, y, width, height} = rect
    radius = { tl: radius, tr: radius, br: radius, bl: radius }
    ctx.beginPath()
    ctx.moveTo(x + radius.tl, y)
    ctx.lineTo(x + width - radius.tr, y)
    ctx.quadraticCurveTo(x + width, y, x + width, y + radius.tr)
    ctx.lineTo(x + width, y + height - radius.br)
    ctx.quadraticCurveTo(x + width, y + height, x + width - radius.br, y + height)
    ctx.lineTo(x + radius.bl, y + height)
    ctx.quadraticCurveTo(x, y + height, x, y + height - radius.bl)
    ctx.lineTo(x, y + radius.tl)
    ctx.quadraticCurveTo(x, y, x + radius.tl, y)
    ctx.closePath();
    ctx.fill()
  }

  getFormattedPercentValue = value => {
    const str = value.toString()
    const idx = str.indexOf(".")
    if (idx === -1) {
      return `${str}.0`
    }
    return str.substring(0, idx + 2)
  }

  getInterpolatedPathByCatmullRom = (points) => {
  
    const pointsCount = 2
  
    if (points.length < 4) return points;
  
    const path = []
  
    points.unshift(points[0])
    points.push(points[points.length - 1])
  
    path.push(points[0])
  
    for (let index = 1; index < points.length - 2; index++) {
  
      const p0 = points[index - 1]
      const p1 = points[index]
      const p2 = points[index + 1]
      const p3 = points[index + 2]
    
      const moment = 1 / pointsCount
    
      for (let i = 1; i < pointsCount; i++) {
  
        const t = moment * i
        const t2 = t * t
        const t3 = t * t2
       
        const v0x = (p2.x - p0.x) * 0.5
        const v1x = (p3.x - p1.x) * 0.5
        const v0y = (p2.y - p0.y) * 0.5
        const v1y = (p3.y - p1.y) * 0.5
  
        const xx = (2 * p1.x - 2 * p2.x + v0x + v1x) * t3 + (-3 * p1.x + 3 * p2.x - 2 * v0x - v1x) * t2 + v0x * t + p1.x
        const yy = (2 * p1.y - 2 * p2.y + v0y + v1y) * t3 + (-3 * p1.y + 3 * p2.y - 2 * v0y - v1y) * t2 + v0y * t + p1.y
        path.push({ x: xx, y: yy })
      }
      path.push(p2)
    }
    path.push(points[points.length - 1])
  
    return path
  }

  render() {
    return <canvas className={this.props.className} ref={ref => this.canvas = ref} width={this.props.width} height={this.props.height} style={{ top: this.props.width - this.props.height, width: this.props.width, height: this.props.height }}></canvas>
  }
}

Wave.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  sideMargin: PropTypes.number.isRequired,
  bottomMargin: PropTypes.number.isRequired,
  topMargin: PropTypes.number.isRequired,
  track: PropTypes.object.isRequired
}

export default Wave

class Rect {
  constructor(x, y, width, height) {
    this.x = x
    this.y = y
    this.width = width
    this.height = height
  }
  get left() {
    return this.x
  }
  get right() {
    return this.x + this.width
  }
  get top() {
    return this.y
  }
  get bottom() {
    return this.y + this.height
  }

  intersects = rect => (
    this.left < rect.right && 
    this.right > rect.left && 
    this.top < rect.bottom && 
    this.bottom > rect.top
  )

  set center(p) {
    this.x = p.x - this.width / 2
    this.y = p.y - this.height / 2
  }

  get center() {
    return new Point(this.x, this.y)
  }

  copy = () => new Rect(this.x, this.y, this.width, this.height)

  getPaddedRect(padding) {
    return new Rect(
      this.x - padding, 
      this.y - padding, 
      this.width + padding * 2, 
      this.height + padding * 2, 
    )
  }
}

class Point {
  constructor(x, y) {
    this.x = x
    this.y = y
  }
}
