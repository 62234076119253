import React from 'react'
import Tracks from '../../components/Tracks'
import { useIntl } from "gatsby-plugin-intl"
import { Location } from '@reach/router';
import SiteHeader from '../../components/SiteHeader'
import SEO from '../../components/SEO'

function sotd(props) {
    const intl = useIntl()
    return (
        <Location>
            {locationProps => (
                <>
                <SEO className="sotd" title={intl.formatMessage({ id: 'sotd.title' })} {...locationProps} />
                <SiteHeader {...locationProps} {...props} removeToTop={true} />
                <Tracks {...locationProps} {...props} />
                </>
            )}
        </Location>
    )
}

export default sotd

