import React, { Component } from 'react'
import  { connect } from 'react-redux'

import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import {  playWithPlayer, 
          pauseWithPlayer, 
          stopWithPlayer, 
          previousWithPlayer, 
          nextWithPlayer
        } from '../actions'

import '../css/player.scss';
import '../css/tracks.scss'

class Footer extends Component {
  constructor(props, context) {
    super(props, context)
    this.startTween = false
    this.needAction = false
    this.isFirstRender = true
  }

  componentDidMount() {
    console.log("componentDidMount")
  }
  componentWillUnmount() {
    this.props.childRef(undefined)
  }

  componentDidUpdate(prevProps, prevState) {
    console.log("componentDidUpdate F", prevProps.playState, this.props.playState) 
  }

  onPrevious = (e) => {
    this.props.previousWithPlayer()
  }
  onNext = (e) => {
    this.props.nextWithPlayer()
  }
  onPlay = (e) => {
    this.props.playWithPlayer(this.props.currentPlayingTrackIndex, this.props.track)
  }
  onPause = (e) => {
    this.props.pauseWithPlayer(this.props.currentPlayingTrackIndex, this.props.track)
  }
  onEnded = (e) => {
    this.props.stopWithPlayer(this.props.currentPlayingTrackIndex, this.props.track)
  }

  onThumbnailClick = e => {
    this.props.scrollTo(this.props.currentPlayingTrackIndex)
  }

  render() {
    console.log("render footer")

    let src = ""
    let jacket = null
    let alt = null
    if (this.props.track) {
      src = this.props.track.m4a
      jacket = this.props.track.jacket
      alt = this.props.track.trackName
      this.startTween = true
    }

    const footerClass = this.startTween ? "sotdFooter appear" : "sotdFooter hidden"
    const isAutoPlay = !this.isFirstRender
    this.isFirstRender = false
 
    return (
      <div className={footerClass}>
        <LazyLoadImage 
          effect="opacity"
          src={jacket} 
          alt={alt}
          className="thumbnail" 
          onClick={this.onThumbnailClick}
          />
        <AudioPlayer
          ref={this.props.childRef}
          autoPlay={isAutoPlay}
          src={src}
          showSkipControls={true}
          showJumpControls={false}
          showLoopControl={false}
          onClickPrevious={this.onPrevious}
          onClickNext={this.onNext}
          onPlay={this.onPlay} 
          onPause={this.onPause}
          onEnded={this.onEnded}
          className="audioPlayer"
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({ playState: state.player.playState, 
                                  currentPlayingTrackIndex: state.player.currentPlayingTrackIndex, 
                                  track: state.player.track })

const mapDispatchToProps = ({ playWithPlayer, 
                              pauseWithPlayer, 
                              stopWithPlayer, 
                              previousWithPlayer, 
                              nextWithPlayer
                            })

export default connect(mapStateToProps, mapDispatchToProps)(Footer)

