const apigClientFactory = require('aws-api-gateway-client').default

class SOTDData {
  constructor(lang) {
    this.lang = (lang === "en") ? "US" : "JP"
    this.latestDate = "0"
    this.tillDate = "0"
    this.loadedPageIndex = 0
    this.isFullLoaded = false
    this.isNowLoading = false
    this.AWS = null
  }

  loadMoreRows(tracks) {
    console.log("loadMoreRows", window)

    if (!this.AWS) {
      this.AWS = window.AWS
      this.AWS.config.region = 'ap-northeast-1'
      this.AWS.config.credentials = new this.AWS.CognitoIdentityCredentials({
        IdentityPoolId: 'ap-northeast-1:9a73997e-c377-4c3e-ab2c-1c466ccfd141'
      })
    }
    if (this.isFullLoaded || this.isNowLoading) return;
    this.isNowLoading = true
 
    //let AWS = window.AWS
    this.AWS.config.credentials.get(err => {
      if (err) {
        this.isNowLoading = false
        this.promiseReject(err)
        return
      }

      const apigClient = apigClientFactory.newClient({
        invokeUrl:'https://cqus2v7ztf.execute-api.ap-northeast-1.amazonaws.com/MobileHub_Deployments',
        region: 'ap-northeast-1',
        accessKey: this.AWS.config.credentials.accessKeyId,
        secretKey: this.AWS.config.credentials.secretAccessKey,
        sessionToken: this.AWS.config.credentials.sessionToken 
      });
 
      apigClient.invokeApi({}, `/songs/${this.lang}_${this.loadedPageIndex}_${this.latestDate}_${this.tillDate}_2`, 'GET', {}, {})
        .then(result => {
          if (this.loadedPageIndex === 0) {
            this.latestDate = result.data.date ?? "0"
          }
          this.tillDate = result.data.startKey ?? "0"
          if (this.tillDate === "0") {
            this.isFullLoaded = true
          }
          let arr = []
          for (let i = 0; i < result.data.songs.length; i++) {
            const d = result.data.songs[i]
            //console.log(d)

            let price = ""
            if (d["itp"] && d["ity"]) {
              const tp = parseInt(d["itp"])
              const cr = d["ity"]
              if (!isNaN(tp)) {
                if (cr === "JPY") {
                  price = "¥" + tp
                }
                else if (cr === "USD") {
                    price = "$" + tp
                }
              }
            }
            const url = d.itl + ((d.itl.indexOf("?") !== -1) ? "&at=10lsGw" : "?at=10lsGw")
            let at = ""
            if (d.itb) {
              at += d.itb
            }
            if (d.itn) {
              at += " ー " + d.itn
            }

            const track = {
              trackName: d.itt,
              albumAndArtist: at,
              athumb: d.athumb, 
              jacket: d.itj,
              wave: d.wave, 
              c0: Number(d.c0), 
              c1: Number(d.c1), 
              c2: Number(d.c2), 
              m4a: d.itu,
              date: d.date, 
              price: price, 
              url: url, 
              genre: d.itg, 
              rdate: d.itd, 
              showLR: true
            }
            if (d.wpt && d.wpq && d.wpu) {
              track.wpt = d.wpt
              track.wpu = d.wpu
              track.wpq = d.wpq
            }
            arr.push(track)
          }
          if (!this.isFullLoaded) arr.push({ loading: true })

          if (tracks.length > 0 && tracks[tracks.length - 1].loading === true) {
            tracks.pop()
          }     
          this.isNowLoading = false
          tracks = [...tracks, ...arr]
          this.rowCount = tracks.length
          this.loadedPageIndex += 1
          this.promiseResolve(tracks)

        }).catch(err => {
          this.isNowLoading = false
          this.promiseReject(err)
        })
    })
    return new Promise((resolve, reject) => {
      this.promiseResolve = resolve
      this.promiseReject = reject
    })

  }

  restore(data) {
    this.tracks = data.tracks
    this.latestDate = data.latestDate
    this.tillDate = data.tillDate
    this.loadedPageIndex = data.loadedPageIndex
    this.isFullLoaded = data.isFullLoaded
  }
  
  getLoadingState() {
    return {
      latestDate: this.latestDate, 
      tillDate: this.tillDate, 
      loadedPageIndex: this.loadedPageIndex, 
      isFullLoaded: this.isFullLoaded
    }
  }

}

export default SOTDData
